export const pushToGtmDataLayer = async (array) => {
    const dataLayerData = {}

    // Create an object with key value pairs to pass to the GTM data layer
    for (let i = 0; i < array.length; i++) {
        dataLayerData[array[i][0]] = array[i][1]
    }

    // Push to Google Tag Manager data layer
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(dataLayerData)
}
