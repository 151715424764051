const cleanedParam = (fromParam) => {

    const allowedLeadsources = process.env.GATSBY_OFFICELINE_SOURCESLIST.split(", ");
    let cleaned = fromParam
    cleaned = cleaned.replaceAll("-",".")
    //cleaned = cleaned.replaceAll("_",".")

    let returnedLeadsource = '';

    if(allowedLeadsources.includes(cleaned)){
        returnedLeadsource = cleaned;
    }else{
        returnedLeadsource = process.env.GATSBY_OFFICELINE_SOURCE;
    }

    return returnedLeadsource
}

export default cleanedParam
