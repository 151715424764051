import moment from 'moment'

// Pass in single values for day, month and year and get back a concatenated string. If any of these values are undefined, return blank. Used to format DOBs from single select fields.
// 4 March 2000
export const formatHumanDateFromSingleValues = (day, month, year) => {
    if (day === '' || month === '' || year === '') {
        return ''
    } else {
        return `${day} ${month} ${year}`
    }
}

// To show within user confirmations and to send through email so the appointment date is readable
// Wednesday, 09 February 2022, 10:45
export const formatHumanAppointmentDateTime = (dateTime) => {
    const date = moment(dateTime).format('LLLL')

    return date
}

// Format current date as DD/MM/YYYY
export const formatStandardDate = () => {
    const date = moment(new Date()).format('DD/MM/YYYY')

    return date
}

// Format current time as hh:mm:ss
export const formatStandardTime = () => {
    const date = moment(new Date()).format('HH:mm:ss')

    return date
}

// Used to format DOB from Office Line from separate values
// 2000-05-01 (YYYY-MM-DD)
export const formatOfficeLineDob = (day, month, year) => {
    const date = moment(`${month} ${day} ${year}`).format('YYYY-MM-DD')

    return date
}

export const generateMonth = (date) => {
    const month = moment(date).format('MMMM')

    return month
}
